"use strict";

$(document).ready(function () {

    var paddingTop = '6.2rem',
        widthSmall = 768,
        widthMedium = 992,
        widthLarge = 1200,
        responsiveAkantHeight = 651;
    
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();

    function calcValuesForFullPageJs() {
        var headerHeight = $('.header').outerHeight(true);
        if (windowWidth >= widthSmall && windowHeight >= responsiveAkantHeight) {
            paddingTop = headerHeight;
        } else {
            paddingTop = 0;
        }
    }

    function markParentSlider() {
        var sliderSect = $('.slider').parent('.section');

        sliderSect.addClass('sliderSection');
    }

    function addNegativeMarginToProducts() {
        var productImageCol = $('.product-image-col');

        if (windowWidth >= widthLarge && windowHeight > responsiveAkantHeight) {
            productImageCol.each(function () {
                var productImageColWidth = $(this).width(),
                    tableCell = $(this).parents('.fp-tableCell'),
                    tableCellHeight = tableCell.height(),
                    tableCellWidth = tableCell.width(),
                    productImageImg = $(this).find('.product-image img'),
                    imageHeight = productImageImg.height(),
                    imageWidth = productImageImg.width();

                var tableCellRatio = tableCellHeight / imageHeight,
                    productImageColRatio = productImageColWidth / imageWidth,
                    newHeight,
                    newWidth,
                    ratio;

                if (tableCellRatio < productImageColRatio) {
                    ratio = tableCellRatio * 0.9;
                    newHeight = imageHeight * ratio;
                    newWidth = imageWidth * ratio;

                    productImageImg.height(newHeight);
                    productImageImg.width(newWidth);
                } else {
                    ratio = productImageColRatio * 0.9;
                    newHeight = imageHeight * ratio;
                    newWidth = imageWidth * ratio;

                    productImageImg.height(newHeight);
                    productImageImg.width(newWidth);
                }
            });
        }

        if(windowHeight > responsiveAkantHeight){
            productImageCol.each(function () {
                var productDescCol = $(this).siblings('.product-desc-col'),
                    productDescHeight = productDescCol.height(),
                    heightDiff = $(this).height() - productDescHeight,
                    newMargin,
                    newHeight;

                if (heightDiff > 0) {
                    newMargin = (-(1 / 2) * heightDiff) + 20;
                    newHeight = $(this).height() - newMargin;
                    if ((!$(this).parents('.fp-scroller').height() < newHeight) && (windowWidth >= widthMedium)) {
                        $(this).css("margin-top", newMargin);
                        $(this).parent('.main-row').css("margin-top", -newMargin);
                    }
                } else if (heightDiff < 0) {
                    newMargin = ((1 / 2) * heightDiff);
                    newHeight = productDescCol.height() - newMargin;
                    if ((!productDescCol.parents('.fp-scroller').height() > newHeight) && (windowWidth >= widthMedium)) {
                        productDescCol.css("margin-top", newMargin)
                    }
                }

                var slides = $(this).parents('.fp-slides');
                if (slides.length) {
                    var arrows = slides.siblings('.fp-controlArrow');
                    if (windowWidth >= widthSmall) {
                        arrows.css('margin-top', newMargin / 2);
                    } else {
                        arrows.css('margin-top', newMargin / 4);
                    }
                }
            });
        }

        markParentSlider();
    }

    function prepareScrollableSections() {
        var sections;
        sections = $('.section:not(.hero-section):not(.contact-section)');

        sections.removeClass('fp-noscroll');
        sections.addClass('xtra-pad');
    }

    function activateFullPageJs() {

        var anchorElems = $('*').filter(function () {
                return $(this).data('anchor') !== undefined;
            }),
            anchors = [];

        anchorElems.each(function () {
            anchors.push($(this).data('anchor'));
        });

        var scrollOverflow = false;
        if (windowWidth >= widthSmall) {
            scrollOverflow = true; // ?
        }

        var g = $('#fullpage').fullpage({
            anchors: anchors,
            navigation: true,
            normalScrollElements: ".ingredients-tab",
            menu: '#menu',
            normalScrollElementTouchThreshold: 5,
            scrollOverflow: scrollOverflow,
            scrollOverflowOptions: {
                //click: true
            },
            afterRender: addNegativeMarginToProducts,
            paddingTop: paddingTop,
            responsiveWidth: widthSmall,
            responsiveHeight: responsiveAkantHeight,
            afterLoad: function() {
                var locHref = location.href;
                var anchorMenu = $("[data-menuanchor='produkty']");
                if(locHref.indexOf('produkty') > -1) {
                    if(!anchorMenu.hasClass('active')) anchorMenu.addClass('active')
                }
                else {
                    if(anchorMenu.hasClass('active')) anchorMenu.removeClass('active')
                }
            },
            afterRender: function() {
                $.fn.fullpage.setAllowScrolling(false, 'right, left'); //some shitty approach
            }
        });

        $('.fp-next').click(function(){
            $.fn.fullpage.moveSlideRight();
        })

        $('.fp-prev').click(function(){
            $.fn.fullpage.moveSlideLeft();
        })
    }

    function checkScrolledDown(){
        window.addEventListener('scroll', checkScrolled);
        $(document).ready(function(){
            checkScrolled()
        });

        function checkScrolled() {
            var body = $('body');
            if (window.pageYOffset > 50) {
                body.addClass('scrolled')
            } else {
                body.removeClass('scrolled')
            }
        }
    }

    function bindMenuItemsOnSmall() {
        if (windowWidth < widthSmall || windowHeight < responsiveAkantHeight) {
            var anchors = $("[data-menuanchor]"),
                offsetTop = 100;

            if(windowHeight < responsiveAkantHeight){
                offsetTop = 120;
            }

            anchors.each(function () {

                $(this).click(function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //console.log(e);

                    var dataAnchor = $(this).data('menuanchor'),
                        section = $("[data-anchor=" + dataAnchor + "]");

                    $('html, body').animate({
                        scrollTop: section.offset().top - offsetTop
                    }, 700);
                })
            });
            //console.log(anchors);
        }
    }

    function activateMenuButton() {
        var menuButton = $('#toggleMenuButton'),
            mainNav = $('#mainNav');

        menuButton.click(function () {
            mainNav.toggleClass('visible');
            $('body').toggleClass('menu-open');
        });
    }

    function bindHideMobileMenu() {
        var mainNav = $('#mainNav'),
            menuLink = $('.menu-btn');

        menuLink.click(function () {
            mainNav.removeClass('visible');
            $('body').removeClass('menu-open');
        })
    }

    function bindInfoSmallButton() {
        var infoSmallButton = $('.show-info-small');
        $('.product-info-small-text').hide();

        infoSmallButton.each(function () {
            var infoSmallText = $(this).siblings('.product-info-small-text');

            $(this).click(function (e) {
                e.preventDefault();
                $(this).toggleClass('active');
                infoSmallText.slideToggle(300);
            })
        });

        var slideDownMobileButton = $('.slide-down-mobile-button');

        slideDownMobileButton.each(function(){
            var dataSlideDown = $(this).data('slide-down'),
                slideDownElems = $('.slide-down-mobile').filter(function(){
                    return $(this).data('slide-down') === dataSlideDown;
                });

            $(this).click(function(e){
                e.preventDefault();
                $(this).toggleClass('active');
                slideDownElems.slideToggle(300);
            })
        })
    }

    function bindIngredientsButton() {
        var ingredientsButton = $('.ingredients-button'),
            body = $('body');

        function hideIngredientsPopup(that, ingredientsTab, parentMainRow) {
            ingredientsTab.removeClass('visible');
            parentMainRow.removeClass('ingredients-visible');
            that.removeClass('active');
            body.removeClass('popupOpen');

            $(document).off('mouseup');
        }

        function showIngredientsPopup(that, ingredientsTab, parentMainRow) {
            ingredientsTab.addClass('visible');
            parentMainRow.addClass('ingredients-visible');
            that.addClass('active');
            body.addClass('popupOpen');

            $(document).mouseup(function (e) {
                if (!ingredientsTab.is(e.target) && !that.is(e.target) && ingredientsTab.has(e.target).length === 0) {
                    ingredientsTab.removeClass('visible');
                    body.removeClass('popupOpen');
                    ingredientsButton.removeClass('active');
                    $(document).off('mouseup');
                }
            });
        }

        function toggleIngredientsTab(that, ingredientsTab, parentMainRow) {

            if (body.hasClass('popupOpen')) {
                hideIngredientsPopup(that, ingredientsTab, parentMainRow)
            } else {
                showIngredientsPopup(that, ingredientsTab, parentMainRow)
            }
        }

        ingredientsButton.each(function () {
            var that = $(this),
                parentMainRow = that.parents('.main-row'),
                ingredientsTab = parentMainRow.find('.ingredients-tab');

            that.click(function (e) {
                e.preventDefault();
                toggleIngredientsTab(that, ingredientsTab, parentMainRow);
            })
        });

        function bindPopupClose() {
            var popupClose = $('.popup-close'),
                tab = $('.ingredients-tab'),
                visible = 'visible';

            popupClose.click(function () {
                var that = $(this);

                ingredientsButton.removeClass('active');
                tab.removeClass(visible);
                body.removeClass('popupOpen');
            })
        }

        bindPopupClose();
    }

    function initParallax() {
        $('.parallax-container').parallax();
    }

    function preventDef() {
        function handler(e, i) {
            e.addEventListener('click', function(ev) {
                ev.preventDefault();
            })
        }

        let els = $('body').find('.research-button');

        for(let i = 0; i < els.length; i++) {
            handler(els[i], i);
        }
    }

    function init() {
        $('.about-section').removeClass('product-section');
        prepareScrollableSections();
        calcValuesForFullPageJs();
        activateFullPageJs();
        checkScrolledDown();
        activateMenuButton();
        bindInfoSmallButton();
        bindMenuItemsOnSmall();
        bindHideMobileMenu();
        bindIngredientsButton();
        initParallax();
        preventDef();
        $(window).resize(function () {

            if(Modernizr.touchevents){
                if($(window).width() !== windowWidth){
                    location.reload();
                }
            } else {
                location.reload();
            }

            windowWidth = $(window).width();
        })
    }

    init();
});

$(window).on('load', function () {
    $('#loader').hide();
});